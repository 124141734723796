<template>
  <div>
    <label>{{required ? label + '*' : label}}</label>

    <div class="risk-checklist">

      <template v-if="showCheckboxes">
        <v-row>
          <v-col cols="6">
            <template v-for="o in firstSet">
              <v-checkbox v-model="newValue[o.value]"
                          color="#002B49"
                          :key="o.value"
                          @change="changeCheckbox(o.value, $event, o.v2ContractName)"
                          :disabled="disabled || disabledOption(o.value)">
                <template v-slot:label>
                  <span v-html="o.text"></span>
                </template>
              </v-checkbox>
            </template>
          </v-col>
          <v-col cols="6">
            <template v-for="o in secondSet">
              <v-checkbox v-model="newValue[o.value]"
                          color="#002B49"
                          :key="o.value"
                          @change="changeCheckbox(o.value, $event, o.v2ContractName)"
                          :disabled="disabled || disabledOption(o.value)">
                <template v-slot:label>
                  <span v-html="o.text"></span>
                </template>
              </v-checkbox>
            </template>
          </v-col>
        </v-row>
      </template>

      <template v-else-if="!showCheckboxes">
        <ul>
          <li v-for="o in options" :key="o.value">
            <span v-html="o.text"></span>
          </li>
        </ul>
      </template>



    </div>

    <div class="hasError" v-if="errors && errors.length"></div>
    <ErrorMessages :errors="errors"></ErrorMessages>
  </div>
</template>

<script>
  import { HighProfileOccupations } from '@/scripts/helper'

  export default {
    name: 'RiskCheckList',
    props: {
      value: Object,
      options: Array,
      label: String,
      required: Boolean,
      errors: Array,
      disabled: Boolean,
      module: String
    },
    emits: [
      'change'
    ],
    data() {
      return {
        newValue: {},
        showCheckboxes: true
      }
    },
    computed: {
      firstSet() {
        if (!this.options) {
          return []
        }
        const optionsCount = Math.floor(this.options.length / 2)
        return this.options.slice(0, optionsCount)
      },
      secondSet() {
        if (!this.options) {
          return []
        }
        return this.options.slice(this.firstSet.length, this.options.length)
      }
    },
    methods: {
      changeCheckbox(name, event, v2ContractName) {
        this.newValue[name] = event;
        this.newValue[v2ContractName] = event;
        this.emitValue();
      },
      emitValue() {
        this.$emit('change', this.newValue)
      },
      disabledOption(value) {
        if (value == 'insuredHighProfileOccupation') {
          const occupation = this.$store.getters.getControlValue(this.module, 'Occupation');
          if (occupation && HighProfileOccupations.find(o => o.toLowerCase() == occupation.toLowerCase())) {
            return true;
          }
        }
        return false;
      }
    },
    watch: {
      value: {
        immediate: true,
        deep: true,
        handler(newVal) {
          if (newVal !== null && newVal !== undefined) {
            this.newValue = newVal;
          }
        }
      }
    },
    mounted() {
      this.options.forEach(o => {
        if (!this.newValue[o.value]) {
          this.newValue[o.value] = false;
        }
        if (o.v2ContractName === null || o.v2ContractName === undefined) {
          return;
        }

        if (this.newValue[o.v2ContractName] != null && this.newValue[o.v2ContractName] != undefined) {
          this.newValue[o.value] = this.newValue[o.v2ContractName];
        } else {
          this.newValue[o.v2ContractName] = this.newValue[o.value];
        }
      })

      this.$store.watch(() => {
        this.showCheckboxes = this.$store.getters.getControlValue(this.module, 'AnyOtherExposures');
        if (!this.showCheckboxes) {
          this.options.forEach(o => {
            this.newValue[o.value] = false;
            this.newValue[o.v2ContractName] = false;
          })
          this.emitValue();
        } else {
          const occupation = this.$store.getters.getControlValue(this.module, 'Occupation');
          if (occupation && HighProfileOccupations.find(o => o.toLowerCase() == occupation.toLowerCase())) {
            if (this.options.find(o => o.value == 'insuredHighProfileOccupation')) {
              this.newValue[o.value] = true;
              this.newValue[o.v2ContractName] = true;
            }
          }
        }

      }, () => { },
        {
          immediate: true
        })
      this.emitValue();
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .risk-checklist {
    padding-top: 10px;
    padding-bottom: 20px;

    ul {
      align-content: flex-start;
      align-items: start;
      column-count: 2;
    }

    span, li {
      font-size: 14px;
      line-height: 24px;
    }

    li {
      padding-bottom: 10px;
    }
  }

  ::v-deep .v-input__slot {
    align-items: start;
  }

  ::v-deep .v-label {
    color: $gray1;
  }

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
  }

  ::v-deep .v-messages {
    display: none;
  }

  .v-application ul, .v-application ol {
    padding-left: 24px;

    li {
      padding-left: 8px;
    }
  }
</style>
